import React from "react"

// Hooks & Querys
import { HomePageFragment } from "../GraphQl/queryFragments" // eslint-disable-line

// Import components
import SEO from "../components/global/seo"
import { BlockZone } from "../components/pageBlocks"

const IndexPage = ({ data }) => {
  const { title, metaDescription, keywords, pageBlocks } =
    data.sanityHomePage || {}
  return (
    <>
      <SEO title={title} keywords={keywords} description={metaDescription} />
      <BlockZone blocks={pageBlocks} />
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  {
    sanityHomePage {
      ...HomePageFields
    }
  }
`
